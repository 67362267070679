*{
    position: relative;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration: none;
}
/* tab box start */
.tab{
    position: fixed;
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    z-index: 9999;
}
.tab-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 300px;
    height: 400px;
    background-color: white;
    border-radius: 10px;
}
.tab-close{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    width: 100%;
    height: 50px;
    padding: 0 15px;
}
.tab-close span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    background-image: url(../src/components/image/close.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.tab-title{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100px;
}
.tab-title h2{
    color: #0D2233;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.tab-phone{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 200px;
}
.tab-phone a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 18px;
    color: #0D2233;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 5px;
}
.tab-phone a span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.ph-ic{
    background-image: url(../src/components/image/phone.png);
}
.vi-ic{
    background-image: url(../src/components/image/viber.png);
}
.wa-ic{
    background-image: url(../src/components/image/whatsapp.png);
}
/* tab box end */
/* header start */
.header{
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: #0D2233;
    padding: 0 25px;
    z-index: 9998;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
    -webkit-box-shadow: 0 0 8px 1px #0D2233;
            box-shadow: 0 0 8px 1px #0D2233;
}
.logo-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 170px;
    height: 25px;
}
.logo-box a,
.logo-box a img{
    width: 100%;
    height: 100%;
}
.menu-open{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    width: 40px;
    height: 21px;
    cursor: pointer;
}
.menu-open span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 3px;
    background-color: white;
    border-radius: 5px;
}
.menu-open span:nth-child(2){
    width: 30px;
}
.menu-open span:nth-child(3){
    width: 20px;
}
/* header end */
/* menu start */
.menu{
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}
.close-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.menu-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 450px;
    height: 100%;
    background-color: #0D2233;
    right: 0;
}
.menu-ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
}
.menu-ul li{
    width: 100%;
    height: 100%;
    list-style: none;
}
.menu-logo-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100px;
    padding: 0 25px;
    border-bottom: 1px solid #3c4f5f;
}
.menu-logo-img{
    width: 170px;
    height: 25px;
}
.menu-link-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 45px;
    padding: 0 25px;
    background-color: #0D2233;
    border-bottom: 1px solid #3c4f5f;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.menu-link{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 45px;
    text-decoration: none;
    color: white;
    letter-spacing: 1px;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.menu-link-box:hover{
    background-color: #1B3246;
}
.menu-link-box:hover .menu-link{
    margin-left: 5px;
}
.service-li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 45px;
    cursor: pointer;
    overflow: hidden;
}
.service-li ul{
    display: none;
}
.service-li:hover .service-li-p{
    margin-left: 5px !important;
}
.service-li p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    min-height: 45px;
    color: white;
    padding: 0 25px;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.service-ul{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.service-ul li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 45px;
    border-top: 1px solid #3c4f5f;
    padding: 0 50px;
    -webkit-transition: all 150ms ease-in-out;
    -o-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.service-ul li a{
    width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 100%;
}
.service-ul li:hover{
    padding: 0 55px;
}
.menu-link-service{
    padding: 0;
}
/* menu end */
/* main start */
.main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 2650px;
}
/* main section 1 start */
.main-sec-1{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 600px;
    background-image: url(components/image/sec-1-bg.jpg);
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
}
.section-1-left{
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 600px;
    padding: 25px;
}
.section-1-left h1{
    color: white;
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 600;
    margin: 35px 0 10px 0;
}
.call-button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 150px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    color: white;
    background-color: rgba(13,34,51, 1);
    text-transform: uppercase;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    letter-spacing: 1px;
}
.call-button:hover .bottom-anim{
    -webkit-transform: scaleY(1) translateY(-17.5px);
        -ms-transform: scaleY(1) translateY(-17.5px);
            transform: scaleY(1) translateY(-17.5px);
}
.call-button:hover .top-anim{
    -webkit-transform: translateY(-17.5px) scaleY(0);
        -ms-transform: translateY(-17.5px) scaleY(0);
            transform: translateY(-17.5px) scaleY(0);
}
.call-anim{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    min-height: 35px;
    border-radius: 5px;
    -webkit-transform: translateY(17.5px);
        -ms-transform: translateY(17.5px);
            transform: translateY(17.5px);
}
.bottom-anim{
    background-color: white;
    color: #0D2233;
    font-weight: 600;
    -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
            transform: scaleY(0);
}
.top-anim{
    /* transform: translateY(17.5px); */
    -webkit-transform-origin: bottom ;
        -ms-transform-origin: bottom ;
            transform-origin: bottom ;
}
.section-1-right{
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 50%;
    height: 600px;
}
.sec-1-cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 100%;
    height: 500px;
    bottom: 0;
}
.sec-1-cont-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 600px;
    height: 200px;
}
.sec-1-row-cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 250px;
    height: 150px;
    background-color: aliceblue;
    border-radius: 5px;
    margin: auto;
}
.sec-1-bg{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px;
}
.sec-1-bg::before{
    position: absolute;
    content: '';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background-color: #C9C1C3;
}   
.sec-1-bg::after{
    position: absolute;
    content: '';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: aliceblue;
    border: 1px solid #684148;
}
.sec-1-bg img{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    height: auto;
    z-index: 1;
}
.sec-1-row-cont p{
    color: #684148;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
}
/* main section 1 end */
/* ****************** */
/* main setion 2 start */
.main-sec-2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 600px;
}
.sec-2-left{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 600px;
    background-image: url(./components/image/sec-2-bg.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.sec-2-right{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    height: 600px;
}
.sec-2-line{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 1px;
    margin-right: 5px;
    background-color: #FF735C;
}
.sec-2-right h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #FF735C;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 200;
}
.sec-2-right h2{
    color: #0D2233;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
}
.sec-2-right p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #6E6E84;
    margin: 20px 0;
}
.sec-2-p{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #0D2233 !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 !important;
    margin-top: 7px;
}
.sec-2-p span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 15px;
    height: 15px;
    background-image: url(./components/image/check.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
}
.sec-2-right button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 150px;
    height: 35px;
    background-color: #0D2233;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 15px;
}
/* main setion 2 end */
/* ******************* */
/* main setion 3 start */
.main-sec-3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 550px;
}
.sec-3-title{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 150px;
}
.sec-3-title h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 18px;
    color: #FF735C;
}
.sec-3-title h3 span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 1px;
    background-color: #FF735C;
    margin: 10px 0;
}
.sec-3-title h2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #0D2233;
    font-size: 28px;
    font-weight: 600;
}
.sec-3-title h2 span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 1px;
    background-color: #0D2233;
    margin: 20px 0;
}
.sec-3-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 300px;
}
.sec-3-cont-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 250px;
    height: 300px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #b8b8b8;
    margin: 0 10px;
    padding: 15px;
}
.sec-3-cont-bg{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 80px;
    height: 80px;
    border: 1px solid #FF735C;
    border-radius: 50%;
}
.sec-3-cont-bg img{
    width: 60%;
    height: auto;
}
.sec-3-cont-box p{
    color: #0D2233;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 5px 0 10px 0;
}
.sec-3-cont-box a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none !important;
    color: #b8b8b8;
}
.sec-3-cont-box a:hover{
    color: #0D2233;
}
.sec-3-cont-box a:hover span{
    -webkit-transform: translateX(5px);
        -ms-transform: translateX(5px);
            transform: translateX(5px);
    background-image: url(./components/image/arrow-dark.png);
}
.arrow-icon{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 10px;
    background-image: url(./components/image/arrrow-right.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 5px;
    -webkit-transition: all 100ms ease-in-out;
    -o-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
}
/* main setion 3 end */
/* main setion 4 start */
.main-sec-4{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 400px;
    background-color: #10273a;
    border: 1px solid black;
}
.main-sec-4 h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #FF735C;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
}
.sec-4-line{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 1px;
    background-color: #FF735C;
    margin-right: 5px;
}
.main-sec-4 h2{
    font-size: 28px;
    letter-spacing: 1px;
    color: white;
    margin: 0 0 20px 0;
}
.main-sec-4 p{
    width: 60%;
    color: white;
    text-align: center;
    letter-spacing: 1px;
}
.main-sec-4 button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 150px;
    height: 35px;
    border: 0;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    text-transform: uppercase;
    margin-top: 15px;
    background-color: white;
    color: #0D2233;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
}
/* main setion 4 end */
/* main setion 5 start */
.main-sec-5{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 500px;
}
.sec-5-title{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 150px;
}
.sec-5-title h2{
    color: #0D2233;
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 10px;
}
.sec-5-title p{
    color: #3c4f5f;
    font-size: 16px;
    width: 40%;
    text-align: center;
    letter-spacing: 1px;
}
.sec-5-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 350px;
}
.sec-5-cont-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 250px;
    height: 300px;
    margin: 0 10px;
    border-radius: 5px;
    padding: 15px;
}
.sec-5-bg{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 25px;
}
.sec-5-bg img{
    width: 35px;
    height: 35px;
    z-index: 1;
}
.sec-5-bg::before{
    content: '';
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90px;
    height: 90px;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background-color: #ebebeb;
}
.sec-5-bg::after{
    content: '';
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background-color: white;
    border: 1px solid #b8b8b8;
}
.sec-5-cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    width: 100%;
    height: 180px;
}
.sec-5-cont h3{
    color: #0D2233;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}
.sec-5-cont p{
    font-size: 15px;
    color: #3c4f5f;
}
/* main setion 5 end */
/* main end */
/* footer start */
.footer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 500px;
    background-color: #1B3246;
}
.footer-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 440px;
    padding: 0 25px;
}
.copyright-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #0D2233;
}
.copyright-box p{
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
}
.footer-social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid white;
}
.footer-logo-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 180px;
    height: 27px;
}
.footer-logo-box img{
    width: 180px;
    height: 27px;
}
.footer-social-icons{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 110px;
    height: 30px;
}
.footer-social-icons img{
    width: 30px;
    height: 30px;
    padding: 1px;
    -webkit-transition: all 100ms ease-in-out;
    -o-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
}
.footer-social-icons img:hover{
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}
.footer-link-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 250px;
    height: 290px;
}
.footer-links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 340px;
}
.footer-link-line{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 2px;
    border-radius: 5px;
    background-color: white;
    margin: 8px 0 10px 0;
}
.footer-links h2{
    color: white;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
}
.footer-links a{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: #afafaf;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 300;
    margin: 3px 0;
}
.footer-links a:hover{
    color: white;
}
.footer-link-box input{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 25px;
    border: 1px solid #6E6E84;
    border-radius: 5px;
    background-color: #1B3246;
    text-indent: 5px;
    color: #C9C1C3;
    outline: none;
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.footer-link-box button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    color: #0D2233;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}
/* footer end */
/* page start */
/* about page start */
.about-main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 600px;
    padding: 0 50px;
}
.about-sec-1{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 500px;
    margin-top: 100px;
}
.about-tit-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100px;
}
.about-tit-box h1{
    font-size: 28px;
    color: #0D2233;
    font-weight: 500;
    letter-spacing: 1px;
}
.about-line{
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 300px;
    height: 3px;
    border-radius: 50%;
    background-color: #0D2233;
    bottom: 0;
}
.about-cont-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 400px;
}
.about-cont-left,
.about-cont-right{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    height: 400px;
}
.about-cont-left h2,
.about-cont-right h2{
    color: #0D2233;
    font-size: 22px;
    letter-spacing: 1px;
    font-weight: 500;
    padding-bottom: 15px;
}
.about-cont-left p,
.about-cont-right p{
    width: 80%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #3c4f5f;
}
/* about page end */
/* contact page start */
.contact-main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 750px;
    padding: 0 50px;
}
.contact-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 50%;
    height: 600px;
    top: 125px;
}
.contact-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100px;
}
.contact-tit{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 180px;
}
.contact-tit h1{
    font-size: 28px;
    color: #0D2233;
    font-weight: 500;
    letter-spacing: 1px;
    padding-bottom: 15px;
}
.contact-tit p{
    font-size: 16px;
    color: #3c4f5f;
    letter-spacing: 1px;
    font-weight: 500;
}
.contact-line{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 2px;
    background-color: #afafaf;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 15px;
}
.contact-box h2{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 22px;
    color: #0D2233;
    letter-spacing: 1px;
    font-weight: 500;
}
.contact-box p{
    font-size: 16px;
    color: #3c4f5f;
    font-weight: 500;
    letter-spacing: 1px;
    padding-top: 5px;
}
.location-icon,
.call-icon{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.location-icon{
    background-image: url(./components/image/location.png);
}
.call-icon{
    background-image: url(./components/image/phone1.png);
}
.contact-mesage{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 50%;
    height: 600px;
    top: 125px;
    border-radius: 15px;
    background-color: #DFDFDF;
}
.message-tit{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 25px;
}
.message-tit h2{
    font-size: 22px;
    color: #0D2233;
    font-weight: 500;
    letter-spacing: 1px;
}
.contact-form-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 500px;
    padding: 0 25px;
}
.contact-form-box form{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
}
.contact-input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 70px;
}
.contact-input-box input{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    text-indent: 5px;
    font-size: 15px;
}
.contact-input-box p{
    color: #3c4f5f;
    font-size: 16px;
    letter-spacing: 1px;
    padding-bottom: 5px;
}
.contact-input-box textarea{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 120px;
    border: none;
    border-radius: 5px;
    outline: none;
    text-transform: capitalize;
    padding: 5px;
    font-size: 16px;
}
.contact-input-box:nth-child(3){
    height: 150px;
}
.contact-button-box button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #0D2233;
    color: white;
    font-size: 16px;
    margin-top: 5px;
}
/* contact page end */
/* ***************** */
/* course page start */
.courses-main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 700px;
}
.courses-title-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    width: 100%;
    height: 200px;
    padding: 0 25px;
}
.courses-title-box h1{
    font-size: 28px;
    font-weight: 600;
    color: #0D2233;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.courses-content-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 500px;
}
.courses-cont-tit{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 25px;
}
.courses-cont-tit p{
    font-size: 16px;
    color: #3c4f5f;
    background-color: #e2e2e2;
    padding: 5px 15px;
    border-radius: 5px;
}
.courses-cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 420px;
    border-top: 1px solid #afafaf;
}
.courses-links{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 300px;
    height: 420px;
    border-right: 1px solid #afafaf;
}
.courses-link-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #afafaf;
    padding-left: 15px;
    cursor: pointer;
}
.courses-link-box:nth-child(1){
    background-color: #FFDFC8;
}
.courses-link-box:nth-child(2){
    background-color: #EBE3A9;
}
.courses-link-box:nth-child(3){
    background-color: #FDD49F;
}
.courses-link-box:nth-child(4){
    background-color: #98B4EE;
}
.link-course{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 180px;
    height: 100%;
    background-color: #0D2233;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: 15px;
    border-radius: 25px 0 0 25px;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}
.html-link-course{
    width: 200px;
}
.courses-icon{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.course-html{
    background-image: url(../src/components/image/html-icon.png);
}
.course-js{
    background-image: url(../src/components/image/js-icon.png);
}
.course-front{
    background-image: url(../src/components/image/front-icon.png);
}
.course-back{
    background-image: url(../src/components/image/back-icon.png);
}
.courses-tabs{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
}
.course-slide-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 350px;
    overflow: hidden;
}
.course-slide{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 100%;
    height: 350px;
    -webkit-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
}
.courses-tabs-tit{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 80px;
    padding: 0 15px;
}
.courses-tabs-tit h2{
    color: #0D2233;
    font-size: 25px;
    letter-spacing: 1px;
    font-weight: 600;
}
.courses-tab-cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 250px;
    padding: 0 15px;
}
.courses-tab-cont p{
    width: 50%;
    font-size: 16px;
    color: #444444;
    letter-spacing: 1px;
    padding: 5px 0;
}
.courses-date{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 5px;
}
.courses-date-icon{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 18px;
    height: 18px;
    background-image: url(../src/components/image/calendar.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
}
.course-tab-buttons{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 15px;
}
.course-tab-buttons a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 180px;
    height: 35px;
    background-color: #273947;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 100;
    border: none;
    outline: none;
    border-radius: 15px;
    margin-right: 15px;
    cursor: pointer;
}
/* course page end */
/* page end */
/* register page start */
.reg-main-box{
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #EBEBEB;
}
.reg-tit{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    height: 100%;
    background-color: #0D2233;
    border-radius: 0 0 50% 0;
}
.reg-cont{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 250px;
    height: 100px;
}
.reg-cont p{
    color: #dbdbdb;
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 400;
}
.reg-logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    height: 45px;
    background-image: url(./components/image/logo.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.reg-form{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    height: 100%;
    background-color: #1B3246;
    border-radius: 50% 0 0 0;
}
.reg-form form{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}
.reg-form form input{
    width: 250px;
    height: 35px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #6E6E84;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
}
.reg-form form button{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 250px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #0D2233;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
}
.reg-form form span{
    color: #ebebeb;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
    padding: 5px;
}
/* register page end */
/* construction page start */
.construct-section{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 600px;
    background-color: #273947;
}
@-webkit-keyframes rotate-box {
    from{
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes rotate-box {
    from{
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.construct-logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    background-image: url(./components/image/setings.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 15px;
    -webkit-animation: rotate-box 8s linear 0ms infinite;
            animation: rotate-box 8s linear 0ms infinite;
}
.construct-section h2{
    color: #ebebeb;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
}
.construct-section p{
    font-size: 16px;
    color: #FF735C;
    letter-spacing: 1px;
    margin: 5px;
}
/* construction page end */
/* 404 page start */
.notfound-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 600px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.notfound-box h1{
    color: #0D2233;
    font-size: 128px;
}
.notfound-box p{
    font-size: 18px;
    color: #0D2233;
}
/* 404 page end */