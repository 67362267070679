@media (min-width: 1024px) and (max-width: 1200px) {
    .sec-1-cont-row{
        width: 100%;
    }
    .sec-1-row-cont{
        width: 230px;
    }
    /* pages media start */
    .course-slide-box{
        height: 380px;
    }
    .courses-content-box{
        height: 530px;
    }
    .courses-cont{
        height: 450px;
    }
    .courses-links{
        height: 450px;
    }
    /* pages media end */
}
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
@media (min-width: 768px) and (max-width: 1023px) {
    .main{
        height: 3200px;
    }
    .main-sec-1{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        background-size: cover;
    }
    .sec-1-cont{
        height: 350px;
    }
    .main-sec-1{
        height: 750px;
    }
    .section-1-right,
    .section-1-left{
        width: 100%;
    }
    .sec-1-cont-row{
        width: 550px;
    }
    .main-sec-2{
        height: 1200px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .sec-2-left,
    .sec-2-right{
        width: 100%;
        height: 400px ;
        padding: 0 25px;
        background-size: 80%;
    }
    .main-sec-3{
        height: 1100px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .sec-3-content{
        width: 768px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        height: 650px;
    }
    .footer{
        height: 550px;
    }
    .footer-content{
        height: 500px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .footer-links{
        width: 568px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .footer-link-box{
        height: 170px;
        padding-top: 25px;
    }
    /* pages media start */
    .courses-cont{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 630px;
    }
    .course-tab-buttons{
        margin-top: 15px;
    }
    .courses-main{
        height: 910px;
    }
    .courses-content-box{
        height: 710px;
    }
    .courses-links{
        height: 270px;
    }
    .contact-line{
        margin-top: 15px;
    }
    .contact-tit{
        height: 250px;
    }
    .contact-tit p{
        font-size: 15px;
        padding: 5px;
    }
    .contact-tit h1{
        font-size: 26px;
    }
    /* pages media end */
}
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
@media (min-width: 568px) and (max-width: 767px) {
    .main{
        height: 3150px;
    }
    .main-sec-1{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        background-size: cover;
    }
    .sec-1-cont{
        height: 350px;
    }
    .main-sec-1{
        height: 750px;
    }
    .section-1-right,
    .section-1-left{
        width: 100%;
        height: 400px;
    }
    .section-1-left{
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
    }
    .sec-1-cont-row{
        width: 550px;
    }
    .main-sec-2{
        height: 800px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .sec-2-left,
    .sec-2-right{
        width: 100%;
        height: 400px ;
        padding: 0 25px;
        background-size: 80%;
    }
    .sec-2-right h3{
        font-size: 16px;
    }
    .sec-2-right h2{
        font-size: 26px;
    }
    .main-sec-3{
        height: 700px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .sec-3-content{
        width: 568px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        height: 440px;
    }
    .sec-3-title h3{
        font-size: 16px;
    }
    .sec-3-title h2{
        font-size: 26px;
    }
    .sec-3-cont-box{
        width: 200px;
        height: 200px;
        padding: 10px;
    }
    .sec-3-cont-bg{
        width: 60px;
        height: 60px;
    }
    .sec-3-cont-box p{
        font-size: 16px;
    }
    .sec-3-cont-box a{
        font-size: 15px;
    }
    .main-sec-4 h2{
        text-align: center;
        font-size: 26px;
    }
    .sec-5-bg,
    .sec-5-bg::after{
        width: 60px;
        height: 60px;
    }
    .sec-5-bg::before{
        width: 70px;
        height: 70px;
    }
    .sec-5-cont h3{
        font-size: 15px;
    }
    .sec-5-cont p{
        font-size: 14px;
    }
    .sec-5-cont-box{
        padding: 0;
    }
    .sec-5-title h2{
        font-size: 26px;
    }
    .sec-5-title p{
        font-size: 15px;
    }
    .footer{
        height: 550px;
    }
    .footer-content{
        height: 500px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .footer-links{
        width: 568px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .footer-link-box{
        height: 170px;
        padding-top: 25px;
    }
        /* pages media start */
        .courses-cont{
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            height: 720px;
        }
        .course-tab-buttons{
            margin-top: 15px;
        }
        .courses-main{
            height: 1000px;
        }
        .courses-content-box{
            height: 800px;
        }
        .courses-links{
            height: 270px;
        }
        .contact-line{
            margin-top: 25px;
        }
        .course-slide-box{
            height: 450px;
        }
        .contact-tit{
            height: 167px;
            margin-bottom: 25px;
        }
        .contact-tit p{
            font-size: 15px;
            padding: 5px;
        }
        .contact-tit h1{
            font-size: 26px;
        }
        .reg-main-box{
            position: absolute;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            height: 800px;
        }
        .reg-form,
        .reg-tit{
            width: 100%;
        }
        .contact-main{
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            height: 1200px;
        }
        .contact-mesage,
        .contact-content{
            width: 100%;
            height: 480px;
        }
        .tab-content{
            width: 230px;
            height: 300px;
        }
        /* pages media end */
}
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */
@media (min-width: 320px) and (max-width: 567px) {
    .main{
        height: 3460px;
    }
    .logo-box img{
        display: none;
    }
    .logo-box{
        width: 30px;
        height: 30px;
        background-image: url(../image/mob-logo.png);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .main-sec-1{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        background-size: cover;
    }
    .sec-1-cont{
        height: 350px;
    }
    .main-sec-1{
        height: 750px;
    }
    .section-1-right,
    .section-1-left{
        width: 100%;
        height: 320px;
    }
    .section-1-left{
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        padding: 15px;
    }
    .sec-1-cont-row{
        width: 320px;
        height: 140px;
        margin-bottom: 15px;
    }
    .section-1-left h1{
        font-size: 22px;
    }
    .sec-1-row-cont{
        width: 140px;
        height: 140px;
        margin: 0 0 0 15px;
    }
    .sec-1-bg::before{
        width: 75px;
        height: 75px;
    }
    .sec-1-bg::after{
        width: 60px;
        height: 60px;
    }
    .sec-1-bg img{
        width: 45%;
    }
    .sec-1-row-cont p{
        font-size: 13px;
    }
    .main-sec-2{
        height: 565px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .sec-2-left,
    .sec-2-right{
        width: 100%;
        height: 365px;
        padding: 0 25px;
        background-size: 320px;
        padding: 0 10px;
    }
    .sec-2-left{
        height: 200px;
    }
    .sec-2-right h3{
        font-size: 15px;
    }
    .sec-2-right p{
        font-size: 14px;
        letter-spacing: 1px;
    }
    .sec-2-p{
        font-size: 12px !important;
        letter-spacing: 0 !important;
        margin-bottom: 10px !important;
    }
    .sec-2-right h2{
        font-size: 22px;
    }
    .main-sec-3{
        height: 850px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .sec-3-content{
        width: 320px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        height: 700px;
    }
    .sec-3-title h3{
        font-size: 15px;
    }
    .sec-3-title h2{
        font-size: 22px;
    }
    .sec-3-cont-box{
        width: 300px;
        height: 160px;
        padding: 10px;
    }
    .sec-3-cont-bg{
        width: 60px;
        height: 60px;
    }
    .sec-3-cont-box p{
        font-size: 16px;
    }
    .sec-3-cont-box a{
        font-size: 15px;
    }
    .main-sec-4 h2{
        text-align: center;
        font-size: 22px;
        font-weight: 500;
    }
    .main-sec-4 h3{
        font-size: 15px;
        padding: 0 5px;
        margin-bottom: 15px;
    }
    .main-sec-4 p{
        width: 100%;
        font-size: 15px;
    }
    .main-sec-5{
        height: 920px;
    }
    .sec-5-bg,
    .sec-5-bg::after{
        width: 60px;
        height: 60px;
    }
    .sec-5-bg::before{
        width: 70px;
        height: 70px;
    }
    .sec-5-cont h3{
        font-size: 15px;
    }
    .sec-5-cont p{
        font-size: 14px;
    }
    .sec-5-cont-box{
        padding: 0;
        height: 180px;
        margin: 25px 10px;
    }
    .sec-5-title h2{
        font-size: 26px;
    }
    .sec-5-title p{
        width: 100%;
        font-size: 15px;
    }
    .sec-5-content{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 700px;
    }
    .sec-5-title{
        height: 170px;
        margin-top: 40px;
    }
    .sec-5-cont{
        height: 120px;
    }
    .footer{
        height: 980px;
    }
    .footer-content{
        height: 920px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .footer-links{
        width: 320px;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .footer-link-box{
        height: 200px;
        padding-top: 25px;
    }
    .footer-links h2{
        font-size: 16px;
    }
    .footer-link-box button{
        font-size: 15px;
    }
    .footer-link-line{
        /* margin-top: auto;
        margin-bottom: auto; */
        margin: 15px 0;
    }
    .footer-logo-box img{
        width: 110px;
        height: 16px;
    }
    .footer-social-icons img{
        width: 25px;
        height: 25px;
    }
    .footer-social-icons{
        width: 90px;
    }
    .footer-logo-box{
        height: 16px;
    }
    .copyright-box p{
        font-size: 14px;
        text-align: center;
    }
            /* pages media start */
            .courses-cont{
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                height: 800px;
            }
            .course-tab-buttons a{
                margin-bottom: 5px;
            }
            .courses-tab-cont{
                height: 338px;
            }
            .course-slide{
                height: 375px;
            }
            .course-tab-buttons{
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                height: 165px;
                margin-top: 15px;
            }
            .courses-main{
                height: 1040px;
            }
            .courses-tab-cont p{
                width: 100%;
            }
            .courses-content-box{
                height: 840px;
            }
            .courses-links{
                height: 270px;
            }
            .contact-line{
                margin-top: 25px;
            }
            .course-slide-box{
                height: 400px;
                margin-top: 15px;
            }
            .contact-tit{
                height: 227px;
                margin-bottom: 25px;
            }
            .contact-tit p{
                font-size: 15px;
                padding: 5px;
            }
            .contact-tit h1{
                font-size: 26px;
            }
            .reg-main-box{
                position: absolute;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                height: 100%;
            }
            .reg-form,
            .reg-tit{
                width: 100%;
            }
            .reg-form{
                border-radius: 30% 0 0 0;
            }
            .contact-main{
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                height: 1200px;
                padding: 0 15px;
            }
            .contact-mesage,
            .contact-content{
                width: 100%;
                height: 480px;
            }
            .tab-content{
                width: 230px;
                height: 300px;
            }
            .construct-section h2,
            .construct-section p{
                text-align: center;
                font-size: 15px;
            }
            .construct-section h2{
                font-size: 18px;
            }
            .construct-section{
                padding: 0 15px;
            }
            .about-main{
                padding: 0 15px;
            }
            .about-tit-box h1{
                font-size: 22px;
            }
            .about-cont-left h2, 
            .about-cont-right h2{
                font-size: 18px;
            }
            .about-cont-left p, 
            .about-cont-right p{
                font-size: 14px;
                width: 100%;
            }
            .about-cont-box{
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
            }
            .about-cont-left, 
            .about-cont-right{
                width: 100%;
                height: 200px;
            }
            /* pages media end */
}